"use client";

import React from "react";
import { CollapsiblePanel, PageWithLayout, useAuth } from "@shesha-io/reactjs";
import { Alert } from "antd";
import styled from "styled-components";

const StyledAlert = styled(Alert)`
  margin-bottom: 15px;
`;

const Home: PageWithLayout<{}> = () => {

  const {
    isLoggedIn,
    loginInfo,
    isFetchingUserInfo
  } = useAuth();
  
  return (
    <div className="mt-3 m-5">
      <CollapsiblePanel className="mt-2 m-5">
        { isLoggedIn && !isFetchingUserInfo && <StyledAlert
          message={`Welcome ${loginInfo.fullName}`}
          type="success"
        />}

      </CollapsiblePanel> 
    </div>
  );
};

export default Home;
